<template>
  <div class="chart-container h-unset" :key="key">
    <div class="chart__heading">
      <div class="chart__heading-title">{{ $t('chart.heading.drivers') }}</div>
      <div class="chart__heading-subtitle">
        <span v-if="startDate && endDate">{{ startDate }} - {{ endDate }}</span>
        <span v-else> {{ filterTitle }} </span>
      </div>
      <b-dropdown
          style="overflow: visible"
          variant="background"
          class="chart-options"
          toggle-class="text-decoration-none rounded-10 px-4 py-4 "
          menu-class="rounded-8 dropdown-menu-show"
          no-caret
          dropleft
      >
        <template v-slot:button-content>
          <vertical-dots-icon/>
        </template>

        <b-dropdown-item @click="()=>changeFilter(7,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_7_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(30,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_30_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(12,'months')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_12_months') }}
          </span>
        </b-dropdown-item>
        <li role="menuitem" class="" style="width: 220px">

          <div>
            <span style="padding:0 12px">{{ $t('chart.range') }}</span>
            <app-date-picker
                @onUpdate="onDatePicked"
            />
          </div>
        </li>


      </b-dropdown>
    </div>

    <div class="chart__options">
      <div class="chart__options-tag"
           :class="item.type==selectedOption?'active':''"
           v-for="item in chartFilterOptions"
           :key="item.type"
           @click="changeOption(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <!--    <v-chart class="chart" style="height: 370px;" :option="option" :autoresize="true"/>-->

    <div style="max-height:580px;overflow-y: auto;margin-top: 16px;">

      <template v-if="data.drivers.length>0">
        <chart-tile
            v-for="(item, index) in data.drivers"
            :key="index"
            :title="percentName"
            :color="driversColors[index]"
            :style="index===4?'margin-bottom: 0;':''"
            :total="data.total"
            :data="item"/>
      </template>

      <div style="margin-top: 100px;" v-else>
        <wameed-no-data
            :title="$t('common.no_data')"
            :sub-title="$t('common.no_data_body')"/>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import WameedDatePicker from "wameed-ui/src/lib-components/WameedDatePicker.vue";
import AppDatePicker from "@/views/pages/reports/charts/appDatePicker.vue";
import ChartTile from "@/views/pages/dashboard/components/chartTile.vue";

export default {
  components: {
    ChartTile,
    AppDatePicker,
    WameedDatePicker,
    DateRangePicker
  },
  props: {
    data: Object
  },
  data() {
    return {

      startDate: null,
      endDate: null,
      key: 0,
      range: [],
      "driversColors": [
        "#00C0F3",
        "#28C76F",
        "#FF9F43",
        "#7367F0",
        "#FED029"
      ],
      percentName: this.$i18n.t('chart.type.order'),

      filter: '',
      selectedOption: 'orders',
      chartFilterOptions: [
        {
          type: 'orders',
          name: this.$i18n.t('chart.options.orders'),
          title: this.$i18n.t('chart.options.orders')
        },
        {
          type: 'reviews',
          title: this.$i18n.t('chart.type.review'),
          name: this.$i18n.t('chart.options.reviews')


        },

      ]
    }
  },
  computed: {


    filterTitle() {
      if (this.filter) {
        const {value, type} = this.filter;
        return this.$i18n.t('chart.last_' + value + '_' + type);
      }

      return this.$i18n.t('chart.last_12_months');
    },


  },

  methods: {
    onDatePicked(d) {
      this.startDate = new Date(d.startDate).toISOString().split('T')[0];
      this.endDate = new Date(d.endDate).toISOString().split('T')[0];

      this.$emit('changeFilter', {
        type: 'range',
        value: JSON.stringify(d)
      });
    },


    changeOption(type) {
      this.selectedOption = type.type
      this.percentName = type.title;
      console.log(this.percentName)
      this.$emit('changeOption', this.selectedOption);

    },
    changeFilter(value, type) {
      this.filter = {value, type};
      if (type != 'range') {
        this.startDate = null;
        this.endDate = null;
      }
      this.$emit('changeFilter', this.filter);
    }
  }
};
</script>

<style scoped>
</style>
