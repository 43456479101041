<template>
  <div class="chart-container h-unset">
    <div class="chart__heading">
      <div class="chart__heading-title">{{ $t('nav.orders.title') }}</div>
      <div class="chart__heading-subtitle">{{ filterTitle }}</div>
      <b-dropdown
        variant="background"
        class="chart-options"
        toggle-class="text-decoration-none rounded-10 px-4 py-4 "
        menu-class="rounded-8  dropdown-menu-show"
        no-caret
        dropleft
      >
        <template v-slot:button-content>
          <vertical-dots-icon/>
        </template>

        <b-dropdown-item @click="()=>changeFilter(7,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_7_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(30,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_30_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(12,'months')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_12_months') }}
          </span>
        </b-dropdown-item>
        <li role="menuitem" class="" style="width: 220px">

          <div>
            <span style="padding:0 12px">{{ $t('chart.range') }}</span>
            <app-date-picker
              @onUpdate="onDatePicked"
            />
          </div>
        </li>


      </b-dropdown>
    </div>
    <div class="chart__options">
      <div class="chart__options-tag"
           :class="item.type==selectedOption?'active':''"
           v-for="item in chartFilterOptions"
           :key="item.type"
           @click="changeOption(item.type)"
      >
        {{ item.name }}
      </div>
    </div>
    <v-chart class="chart" style="height: 370px;" :option="option" :autoresize="true"/>
  </div>
</template>

<script>

import {graphic} from 'echarts';
import AppDatePicker from "@/views/pages/reports/charts/appDatePicker.vue";

export default {
  components: {AppDatePicker},
  props: {
    data: Object
  },
  computed: {
    filterTitle() {
      if (this.filter) {
        const {value, type} = this.filter;
        return this.$i18n.t('chart.last_' + value + '_' + type);
      }

      return this.$i18n.t('chart.last_12_months');
    },


    option() {
      return {
        tooltip: {
          trigger: 'axis',
          formatter: "<span class='tool_tip_text'>" + ' {b0} <br/> {c0} ' + this.$t('common.order') + "</span>",
          // className: "char-bar-tooltip line",
          position: 'left',

        },
        grid: {
          left: '16px',
          right: '16px',
          bottom: '16px',
          containLabel: true
        },
        legend: {
          show: false,
        },
        textStyle: {
          fontFamily: 'din-next'
        },
        color: ['rgba(84, 216, 255)', '#7367F0'],
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.data.xAxis_data
        },
        yAxis: {
          type: 'value'
        },
        series: [


          {
            name: this.$t('financial.sales'),
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 2
            },
            symbolSize: 10,
            areaStyle: {
              opacity: 0.8,
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(84, 216, 255,.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(84, 216, 255,0)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: this.data.series
          },
        ],
        media: [ // each rule of media query is defined here
          {
            query: {
              maxAspectRatio: 1
            },   // write rule here

          },
        ]
      }
    }
  },

  data() {
    return {
      filter: '',
      selectedOption: 'days',
      chartFilterOptions: [
        {
          type: 'days',
          name: this.$i18n.t('chart.options.days')
        },
        {
          type: 'hours',
          name: this.$i18n.t('chart.options.hours')
        },
        {
          type: 'vendors',
          name: this.$i18n.t('chart.options.vendors')
        },
        {
          type: 'drivers',
          name: this.$i18n.t('chart.options.drivers')
        },
        {
          type: 'vehicles',
          name: this.$i18n.t('chart.options.vehicles')
        },
        {
          type: 'services',
          name: this.$i18n.t('chart.options.services')
        },
        {
          type: 'genders',
          name: this.$i18n.t('chart.options.genders')
        },
        {
          type: 'ages',
          name: this.$i18n.t('chart.options.ages')
        },
      ]
    }
  },
  methods: {
    onDatePicked(d) {
      this.$emit('changeFilter', {
        type: 'range',
        value: JSON.stringify(d)
      });
    },
    changeOption(type) {
      this.selectedOption = type
      this.$emit('changeOption', this.selectedOption);
    },
    changeFilter(value, type) {
      this.filter = {value, type};
      this.$emit('changeFilter', this.filter);
    }
  }
};
</script>

<style scoped>

</style>
