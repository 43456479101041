<template>
  <div class="chart-container h-unset" :key="key">
    <div class="chart__heading">
      <div class="chart__heading-title">{{ $t('chart.heading.top_clients') }}</div>
      <div class="chart__heading-subtitle">
        <span v-if="startDate && endDate">{{ startDate }} - {{ endDate }}</span>
        <span v-else> {{ filterTitle }} </span>
      </div>
      <b-dropdown
          style="overflow: visible"
          variant="background"
          class="chart-options"
          toggle-class="text-decoration-none rounded-10 px-4 py-4 "
          menu-class="rounded-8 dropdown-menu-show"
          no-caret
          dropleft
      >
        <template v-slot:button-content>
          <vertical-dots-icon/>
        </template>

        <b-dropdown-item @click="()=>changeFilter(7,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_7_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(30,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_30_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(12,'months')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_12_months') }}
          </span>
        </b-dropdown-item>
        <li role="menuitem" class="" style="width: 220px">

          <div>
            <span style="padding:0 12px">{{ $t('chart.range') }}</span>
            <app-date-picker
                @onUpdate="onDatePicked"
            />
          </div>
        </li>


      </b-dropdown>
    </div>

    <div class="chart__options">
      <div class="chart__options-dropdown">
        <span class="chart__options-dropdown_title">{{ $t("chart.options.by") }}</span>
        <wameed-dropdown
            :searchable="true"
            v-model="service"
            :placeholder="$t('chart.service')"
            variant="disable"
            :items="getServicesList"
            title="name"
            id="type"
            :clearable="true"
            class="wameed_dropdown"
            rules="required"
            :no_options="$t('common.no_options')"
            :loadingMore="$t('common.loadingMore')"
            :no_options_search="$t('common.no_options_search')"

            @input="(v)=>changeService(v.id,'top_clients_statics_service')"
        />
        <wameed-dropdown
            v-if="getVendorsList.length>0"
            :searchable="true"
            v-model="vendor"
            :placeholder="$t('chart.vendor')"
            variant="disable"
            :items="getVendorsList"
            title="name"
            id="type"
            :clearable="true"
            class="wameed_dropdown"
            rules="required"
            :no_options="$t('common.no_options')"
            :loadingMore="$t('common.loadingMore')"
            :no_options_search="$t('common.no_options_search')"

            @input="(v)=>changeVendor(v.id,'top_clients_statics_vendor')"
        />
      </div>

    </div>
    <!--    <v-chart class="chart" style="height: 370px;" :option="option" :autoresize="true"/>-->

    <div style="max-height:580px;overflow-y: auto;margin-top: 16px;">

      <template v-if="data.clients.length>0">
        <chart-tile
            v-for="(item, index) in data.clients"
            :key="index"
            :title="percentName"
            :color="clientsColors[index]"
            :gender="genders[item.gender]"
            :style="index===4?'margin-bottom: 0;':''"
            :total="data.total"
            :data="item"/>
      </template>

      <div style="margin-top: 100px;" v-else>
        <wameed-no-data
            :title="$t('common.no_data')"
            :sub-title="$t('common.no_data_body')"/>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import WameedDatePicker from "wameed-ui/src/lib-components/WameedDatePicker.vue";
import AppDatePicker from "@/views/pages/reports/charts/appDatePicker.vue";
import ChartTile from "@/views/pages/dashboard/components/chartTile.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    ChartTile,
    AppDatePicker,
    WameedDatePicker,
    DateRangePicker
  },
  props: {
    data: Object
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      key: 0,
      range: [],
      service: null,
      vendor: null,
      "clientsColors": [
        "#00C0F3",
        "#28C76F",
        "#FF9F43",
        "#7367F0",
        "#FED029"
      ],
      percentName: this.$i18n.t('chart.type.order'),

      filter: '',
      selectedOption: 'orders',
    }
  },
  computed: {
    ...mapGetters({
      getVendorsList: 'admin/reports/getTopClientsVendor',
      getServicesList: 'admin/reports/getServicesList',
    }),

    genders() {
      return [
        this.$t('common.female'),
        this.$t('common.male'),
        this.$t('common.gender_unset'),
        this.$t('common.gender_unset'),
        this.$t('common.gender_unset'),
        this.$t('common.gender_unset'),
        this.$t('common.gender_unset'),

      ]
    },
    filterTitle() {
      if (this.filter) {
        const {value, type} = this.filter;
        return this.$i18n.t('chart.last_' + value + '_' + type);
      }

      return this.$i18n.t('chart.last_12_months');
    },


  },

  methods: {
    onDatePicked(d) {
      // {"startDate":"2024-01-11T12:04:30.946Z","endDate":"2024-02-11T12:04:30.946Z"}
      // get only the date
      this.startDate = new Date(d.startDate).toISOString().split('T')[0];
      this.endDate = new Date(d.endDate).toISOString().split('T')[0];
      this.$emit('changeFilter', {
        type: 'range',
        value: JSON.stringify(d)
      });
    },


    changeFilter(value, type) {
      this.filter = {value, type};
      if (type != 'range') {
        this.startDate = null;
        this.endDate = null;
      }
      this.$emit('changeFilter', this.filter);
    },
    changeVendor(value) {
      this.$emit('changeVendor', value);
    },
    changeService(value) {
      this.$emit('changeService', value);
      this.vendor = null;
      this.$emit('changeVendor', null);
    }
  }
};
</script>

<style scoped>
</style>
