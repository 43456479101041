<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('nav.reports')"
    />


    <div class="mb-5 home_echart_flex ">
      <services-echart
          class="w-4"
          :data="getDashboardContent.services"
          @changeFilter="serviceStaticsFilter"
          @changeOption="serviceStaticsOption"
      />
      <vendors-echart
          class="w-2"
          :data="getDashboardContent.vendors"
          @changeFilter="vendorStaticsFilter"
          @changeOption="vendorStaticsOption"
      />
    </div>

    <div class="mb-5 ">


      <orders-echart
          :data="getDashboardContent.orders"
          @changeFilter="orderStaticsFilter"
          @changeOption="orderStaticsOption"
      />
    </div>

    <div class="mb-5 home_echart_flex ">
      <drivers-echart
          class="w-4"
          :data="getDashboardContent.drivers"
          @changeFilter="driverStaticsFilter"
          @changeOption="driverStaticsOption"
      />
      <users-echart
          class="w-2"
          :data="getDashboardContent.users"
          @changeFilter="userStaticsFilter"
          @changeOption="userStaticsOption"
      />
    </div>

    <div class="mb-5  ">

      <top-clients-echart
          :data="getDashboardContent.top_clients"
          @changeFilter="topClientsStaticsFilter"
          @changeOption="topClientsStaticsOption"
          @changeVendor="topClientsChangeVendor"
          @changeService="topClientsChangeService"
      />
    </div>

    <div class="mb-5  ">
      <top-products-echart
          :data="getDashboardContent.top_products_by_vendor"
          @changeFilter="topProductsStaticsFilter"
          @changeOption="topProductsStaticsOption"
          @changeVendor="topProductsChangeVendor"
      />
    </div>

    <!--
        todo::add this
        <div class="mb-5 ">


          <offers-echart
              :data="getDashboardContent.offers"
              @changeFilter="offerStaticsFilter"
              @changeOption="offerStaticsOption"
          />
        </div>-->

    <div class="mb-5"></div>


  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import FinancialCard from "@/components/financialCard";

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import EchartPie from "@/views/pages/dashboard/charts/echart-pie";
import OrdersEchart from "@/views/pages/reports/charts/orders-echart.vue";
import EchartBar from "@/views/pages/dashboard/charts/echart-bar";
import ChartTile from "@/views/pages/dashboard/components/chartTile";
import {WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import CheckPrice from "@/components/checkPrice";
import CustomerRate from "@/components/customerRate";
import StatusBadge from "@/components/statusBadge";
import {statues as orderStatues} from "@/enums/orderStatus.enum"
import CustomBadge from "@/components/customBadge";
import CheckValue from "@/components/checkValue";
import DriversEchart from "@/views/pages/reports/charts/drivers-echart.vue";
import UsersEchart from "@/views/pages/reports/charts/users-echart.vue";
import OffersEchart from "@/views/pages/reports/charts/offers-echart.vue";
import ServicesEchart from "@/views/pages/reports/charts/services-echart.vue";
import VendorsEchart from "@/views/pages/reports/charts/vendors-echart.vue";
import TopClientsEchart from "@/views/pages/reports/charts/top-clients-echart.vue";
import TopProductsEchart from "@/views/pages/reports/charts/top-products-echart.vue";

export default {
  components: {
    TopProductsEchart,
    TopClientsEchart,
    VendorsEchart,
    ServicesEchart,
    OffersEchart,
    UsersEchart,
    DriversEchart,
    CheckValue,
    CustomBadge,
    orderStatues,
    WTables,
    StatusBadge,
    CheckPrice,
    CustomerRate,
    EchartBar,
    EchartPie,
    OrdersEchart,
    ChartTile,
    FinancialCard,
    PageHeader
  },
  data() {
    return {
      "vendorsColors": [
        "#00C0F3",
        "#28C76F",
        "#FF9F43",
        "#7367F0",
        "#FED029"
      ],


      vendorFilter: null,
      filterContent: {
        order_statics_type: 'days',
        driver_statics_type: 'orders',
        user_statics_type: 'genders',
        offer_statics_type: 'vendors',
        service_statics_type: 'orders',
        vendor_statics_type: 'orders_count',
        resetVendors: false,
        resetDrivers: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      getDashboardContent: 'admin/reports/getData',
    }),

  },
  methods: {

    orderStaticsFilter(value) {
      this.filterContent.order_statics_date_value = value.value;
      this.filterContent.order_statics_date_type = value.type;

      this.loadData();
    },
    orderStaticsOption(value) {
      this.filterContent.order_statics_type = value;

      this.loadData();
    },

    vendorStaticsFilter(value) {
      this.filterContent.vendor_statics_date_value = value.value;
      this.filterContent.vendor_statics_date_type = value.type;
      this.filterContent.resetVendors = true;

      this.loadData();
    },
    vendorStaticsOption(value) {
      this.filterContent.vendor_statics_type = value;
      this.filterContent.resetVendors = true;

      this.loadData();
    },
    serviceStaticsFilter(value) {
      this.filterContent.service_statics_date_value = value.value;
      this.filterContent.service_statics_date_type = value.type;

      this.loadData();
    },
    serviceStaticsOption(value) {
      this.filterContent.service_statics_type = value;

      this.loadData();
    },

    offerStaticsFilter(value) {
      this.filterContent.offer_statics_date_value = value.value;
      this.filterContent.offer_statics_date_type = value.type;

      this.loadData();
    },
    offerStaticsOption(value) {
      this.filterContent.offer_statics_type = value;

      this.loadData();
    },
    driverStaticsFilter(value) {
      this.filterContent.driver_statics_date_value = value.value;
      this.filterContent.driver_statics_date_type = value.type;
      this.filterContent.resetDrivers = true

      this.loadData();
    },

    topProductsStaticsFilter(value) {
      this.filterContent.top_products_by_vendor_statics_date_value = value.value;
      this.filterContent.top_products_by_vendor_statics_date_type = value.type;

      this.loadData();
    },
    topProductsStaticsOption(value) {
      this.filterContent.top_products_by_vendor_statics_type = value;

      this.loadData();
    },
    topProductsChangeVendor(value, key) {
      this.filterContent.top_products_by_vendor_statics_vendor = value;
      this.loadData();
    },

    topClientsStaticsFilter(value) {
      this.filterContent.top_clients_statics_date_value = value.value;
      this.filterContent.top_clients_statics_date_type = value.type;

      this.loadData();
    },

    topClientsStaticsOption(value) {
      this.filterContent.top_clients_statics_type = value;

      this.loadData();
    },

    topClientsChangeVendor(value, key) {
      this.filterContent.top_clients_statics_vendor = value;
      this.loadData();
    },

    topClientsChangeService(value, key) {
      this.filterContent.top_clients_statics_service = value;
      this.$store.dispatch('admin/reports/getVendors',value)
      this.loadData();
    },



    driverStaticsOption(value) {
      this.filterContent.driver_statics_type = value;
      this.filterContent.resetDrivers = true

      this.loadData();
    },
    userStaticsFilter(value) {
      this.filterContent.user_statics_date_value = value.value;
      this.filterContent.user_statics_date_type = value.type;

      this.loadData();
    },
    userStaticsOption(value) {
      this.filterContent.user_statics_type = value;

      this.loadData();
    },
    loadData() {

      this.$store.dispatch('admin/reports/get', this.filterContent).then(() => {
        this.filterContent.resetVendors = false
        this.filterContent.resetDrivers = false
      });
    },
    toToDetail(id) {
      return {
        name: 'order-detail',
        params: {lang: this.$i18n.locale, id},
      };
    }
  },
  async created() {
    await this.$store.dispatch('admin/reports/getVendors');
    await this.$store.dispatch('admin/reports/getServices');
    this.loadData();
  },

};
</script>
